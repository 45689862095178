<template lang="html">
  <div class="h-100">
    <b-card class="h-100" sub-title="Définition des droits des membres" title="Comptes gestionnaires">
      <app-collapse>
        <app-collapse-item title="">
          <div slot="header">
            <h3 class="text-primary font-medium-3 mb-0">Administrateurs</h3>
            <p class="font-small-2 mb-0">Edition des gestionnaires, fiches pratiques, évènements et lieux.</p>
          </div>
          <b-list-group v-if="getAdminMembers.length > 0" flush>
            <b-list-group-item
                v-for="member in getAdminMembers"
                v-bind:key="member.id"
                class="d-flex align-items-center pl-0 pr-0">
              <b-avatar :text="getAvatarText(member)" variant="dark"/>

              <a :href="'mailto:' + member.user.email" class="font-weight-bold text-dark pl-1 overflow-hidden">
                {{ member.user.first_name + ' ' + member.user.last_name }}
              </a>

              <div v-if="isUserAdmin && member.user.id !== user.id" class="d-flex ml-auto">
                <b-button class="btn-icon" variant="flat-primary" @click="showEditMemberDialog(member.id)">
                  <feather-icon icon="EditIcon"/>
                </b-button>
                <b-button class="btn-icon" variant="flat-danger" @click="showDeleteMemberDialog(member.id)">
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </div>
            </b-list-group-item>
          </b-list-group>
          <p v-else class=" text-center text-primary">
            Aucun membre n'a le rôle d'administrateur
          </p>
        </app-collapse-item>
        <app-collapse-item class="show" title="">
          <div slot="header">
            <h3 class="text-secondary font-medium-3 mb-0">Staff</h3>
            <p class="font-small-2 mb-0">Edition des fiches pratiques, évènements et lieux.</p>
          </div>
          <b-list-group v-if="getStaffMembers.length > 0" flush>
            <b-list-group-item
                v-for="member in getStaffMembers"
                v-bind:key="member.id"
                class="d-flex align-items-center pl-0 pr-0">
              <b-avatar :text="getAvatarText(member)" variant="dark"/>
              <a :href="'mailto:' + member.user.email" class="font-weight-bold text-dark pl-1 overflow-hidden">
                {{ member.user.first_name + ' ' + member.user.last_name }}
              </a>
              <div v-if="isUserAdmin && member.user.id !== user.id" class="d-flex ml-auto">
                <b-button class="btn-icon" variant="flat-primary" @click="showEditMemberDialog(member.id)">
                  <feather-icon icon="EditIcon"/>
                </b-button>
                <b-button class="btn-icon" variant="flat-danger"
                    @click="showDeleteMemberDialog(member.id, member.user.email)">
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </div>
            </b-list-group-item>
          </b-list-group>
          <p v-else class="text-center text-warning">Aucun membre n'a le rôle de staff</p>
        </app-collapse-item>
      </app-collapse>

      <div class="d-flex col-12 justify-content-center pt-2">
        <b-button v-if="isUserAdmin" variant="primary" @click="showAddMemberDialog">
          <feather-icon icon="PlusIcon"/>
          Nouveau membre
        </b-button>
      </div>
    </b-card>

    <validation-observer v-slot="{invalid}">
      <b-modal
          ref="promptAddMember"
          v-model="promptAddMember"
          :ok-disabled="invalid" centered
          ok-title="Ajouter"
          title="Nouveau membre"
          @ok="addMember()">
        <!-- Email -->
        <b-form-group label="Email">
          <validation-provider v-slot="{errors}" rules="required|email">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="MailIcon"/>
              </b-input-group-prepend>
              <b-form-input v-model="editingMemberEmail" autofocus placeholder="Email" type="email"/>
            </b-input-group>
            <b-form-text v-if="promptErrorMessage != null" class="text-danger font-small-3">
              {{ promptErrorMessage }}.
            </b-form-text>
            <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
          </validation-provider>
        </b-form-group>

        <!--Rôle-->
        <b-form-group label="Rôle">
          <validation-provider v-slot="{errors}" rules="required|oneOf:admin,staff">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="UserIcon"/>
              </b-input-group-prepend>
              <b-form-select v-model="editingMemberRole" :options="optionsMemberSelectRole">
              </b-form-select>
            </b-input-group>
            <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
          </validation-provider>
        </b-form-group>
      </b-modal>
    </validation-observer>

    <b-modal
        ref="promptEditMember"
        v-model="promptEditMember"
        cancel-title="Annuler"
        centered
        ok-title="Modifier"
        title="Modifier le rôle"
        @ok="editMember()">

      <!--Rôle-->
      <b-form-group label="Rôle">
        <validation-provider v-slot="{errors}" rules="required">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon"/>
            </b-input-group-prepend>
            <b-form-select v-model="editingMemberRole" :options="optionsMemberSelectRole">
            </b-form-select>
          </b-input-group>
          <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
        </validation-provider>
      </b-form-group>
    </b-modal>

    <b-modal
        ref="promptDeleteMember"
        v-model="promptDeleteMember"
        cancel-title="Annuler"
        centered
        ok-title="Supprimer"
        ok-variant="danger"
        title="Suppression du membre"
        @ok="deleteMember()">
      <b-alert show variant="danger">
        Êtes-vous sûr de vouloir supprimer ce membre ?
      </b-alert>
      <p class="text-center font-weight-bolder">{{ selectedMemberEmail ? selectedMemberEmail : '' }}</p>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import JayUpload from "@/components/global/JayUpload";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {email, oneOf, required} from "vee-validate/dist/rules";
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormText,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BRow,
} from "bootstrap-vue";

extend('required', {...required, message: 'Ce champ est requis'});
extend('email', {...email, message: 'Email invalide'});
extend('oneOf', {...oneOf, message: 'Le rôle peut être seulement "Administrateur" ou "Staff"'});

export default {
  components: {
    JayUpload,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCard,
    BCol,
    BModal,
    BRow,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormSelect,
    BFormText,
    BAlert,
  },

  data() {
    return {
      promptErrorMessage: null,

      promptAddMember: false,
      promptEditMember: false,
      promptDeleteMember: false,

      selectedMember: null,
      selectedMemberEmail: null,
      editingMemberEmail: null,
      editingMemberRole: null,

      optionsMemberSelectRole: [
        {value: null, text: 'Veuillez sélectionner une option', disabled: true},
        {value: 'staff', text: 'Staff'},
        {value: 'admin', text: 'Administrateur'},
      ]
    }
  },
  beforeDestroy() {
    this.$refs.promptAddMember.$el.parentNode.removeChild(this.$refs.promptAddMember.$el)
    this.$refs.promptAddMember.$destroy()
    this.$refs.promptEditMember.$el.parentNode.removeChild(this.$refs.promptEditMember.$el)
    this.$refs.promptEditMember.$destroy()
    this.$refs.promptDeleteMember.$el.parentNode.removeChild(this.$refs.promptDeleteMember.$el)
    this.$refs.promptDeleteMember.$destroy()
  },
  computed: {
    user() {
      return this.$store.state.account.user
    },
    localAuthority() {
      // TODO Manage multiple local authority administration
      return this.$store.state.localAuthority.memberOf
    },
    isUserAdmin() {
      let role = this.localAuthority.members.find(member => member.user.id === this.user.id).role
      return role === 'admin'
    },
    getAdminMembers() {
      return this.localAuthority.members.filter(member => member.role === 'admin')
    },
    getStaffMembers() {
      return this.localAuthority.members.filter(member => member.role === 'staff')
    },
  },
  methods: {
    getAvatarText(member) {
      let avatarText = ''
      if (member.user.first_name) {
        avatarText += member.user.first_name[0]
      }
      if (member.user.last_name) {
        avatarText += member.user.last_name[0]
      }
      if (!member.user.first_name && !member.user.last_name) {
        avatarText = '?'
      }
      return avatarText
    },
    showAddMemberDialog() {
      this.promptErrorMessage = null
      this.editingMemberEmail = ""
      this.editingMemberRole = "staff"
      this.promptAddMember = true
    },
    showEditMemberDialog(memberId) {
      this.promptErrorMessage = null
      this.selectedMember = memberId
      this.editingMemberRole = this.localAuthority.members
          .find(member => member.id === memberId).role
      this.promptEditMember = true
    },
    showDeleteMemberDialog(memberId, memberEmail) {
      this.promptErrorMessage = null
      this.selectedMember = memberId
      this.selectedMemberEmail = memberEmail
      this.promptDeleteMember = true
    },
    addMember() {
      this.$loading(true)
      this.$store.dispatch("localAuthority/addMember", {
        'localAuthorityId': this.localAuthority.id,
        'email': this.editingMemberEmail,
        'role': this.editingMemberRole
      }).then(() => {
        this.$notify({
          time: 4000,
          title: "Ajout d'un membre",
          text: "Le membre a bien été ajouté",
          color: "primary"
        })
      }).catch(error => {
        if (error.response && error.response.data.detail === "User not found") {
          this.promptErrorMessage = "L'adresse email ne correspond a aucun utilisateur inscrit"
        } else if (error.response && error.response.data.detail === "The user is already member") {
          this.promptErrorMessage = "Cet utilisateur est déjà membre de la collectivité"
        } else {
          this.promptErrorMessage = "Impossible d'ajouter le nouveau membre"
        }
        this.promptAddMember = true
      }).finally(() => this.$loading(false))
    },
    editMember() {
      this.$loading(true)
      this.$store.dispatch("localAuthority/updateMember", {
        'localAuthorityId': this.localAuthority.id,
        'memberId': this.selectedMember,
        'role': this.editingMemberRole
      }).then(() => {
        this.$notify({
          time: 4000,
          title: "Modification d'un membre",
          text: "Le membre a bien été modifié",
          color: "primary"
        })
      }).catch(() => {
        this.$notify({
          time: 4000,
          title: "Erreur",
          color: "danger",
          text: "Une erreur technique est survenue, impossible d'éditer ce membre."
        })
      }).finally(() => this.$loading(false))
    },
    deleteMember() {
      this.$loading(true)
      this.$store.dispatch("localAuthority/deleteMember", {
        'localAuthorityId': this.localAuthority.id,
        'memberId': this.selectedMember,
        'role': this.editingMemberRole
      }).then(() => {
        this.$notify({
          time: 4000,
          title: "Suppression d'un membre",
          text: "Le membre a bien été supprimé",
          color: "primary"
        })
      }).catch(() => {
        this.$notify({
          time: 4000,
          title: "Erreur",
          color: "danger",
          text: "Une erreur technique est survenue, impossible de supprimer ce membre."
        })
      }).finally(() => this.$loading(false))
    }
  }
}
</script>
