<template lang="html">
  <div class="h-100">
    <b-card class="h-100" sub-title="Informations visibles par tous" title="Profil public">
      <b-row>
        <b-col class="col-12 col-md-6 pb-2 d-flex flex-column justify-content-center">
          <div class="d-flex justify-content-center align-items-center">
            <span class="font-medium-1">Nom</span>
            <b-button
                v-if="isUserAdmin"
                class="btn-icon"
                variant="flat-primary"
                @click="showEditLocalAuthorityNameDialog">
              <feather-icon icon="EditIcon"/>
            </b-button>
          </div>
          <h2 class="text-primary text-center font-large-1">
            {{ localAuthority.name }}
          </h2>
        </b-col>
        <b-col class="col-12 col-md-6 d-flex flex-column justify-content-center">
          <div class="d-flex justify-content-center align-items-center">
            <img :src="getLocalAuthorityPhoto"
                alt="Photo de la collectivité"
                style="max-width: 190px">
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <b-button
                v-if="isUserAdmin"
                class="btn-icon"
                variant="flat-primary"
                @click="showEditLocalAuthorityPhotoDialog">
              <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button
                v-if="localAuthority.photo_url && isUserAdmin"
                class="btn-icon"
                variant="flat-danger"
                @click="showDeleteLocalAuthorityPhotoDialog">
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </div>
        </b-col>
        <b-col class="col-12">
          <jaya-divider color="light"/>
          <div class="d-flex align-items-center">
            <span class="font-medium-3 font-weight-bold">Description</span>
            <b-button
                v-if="isUserAdmin"
                class="btn-icon"
                variant="flat-primary"
                @click="showEditLocalAuthorityDescriptionDialog">
              <feather-icon icon="EditIcon"/>
            </b-button>
          </div>
          <p v-for="(part,id) in localAuthority.description.split('\n')" :key="id" class="m-0 mt-25 overflow-auto text-justify">{{part}}</p>
        </b-col>
      </b-row>
    </b-card>

    <JayUpload :active.sync="promptEditLocalAuthorityPhoto"
        title="Changer de photo"
        @on-success="updateLocalAuthorityPhoto"/>

    <b-modal
        ref="promptDeleteLocalAuthorityPhoto"
        v-model="promptDeleteLocalAuthorityPhoto"
        cancel-title="Annuler"
        centered
        ok-title="Supprimer"
        ok-variant="danger"
        title="Suppression de la photo"
        @ok="deleteLocalAuthorityPhoto">
      <p> Êtes-vous sûr de vouloir supprimer la photo de la collectivité ?</p>
    </b-modal>

    <validation-observer v-slot="{invalid}">
      <b-modal
          ref="promptEditLocalAuthorityName"
          v-model="promptEditLocalAuthorityName"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          centered
          ok-title="Modifier"
          title="Modifier le nom"
          @ok="updateLocalAuthorityName">

        <b-form-group label="Nom de la collectivité :">
          <validation-provider v-slot="{errors}" name="Nom" rules="required|max:50">
            <b-form-input v-model="editingLocalAuthorityName" autofocus placeholder="Nom de la collectivité"/>
            <b-form-text v-if="promptErrorMessage != null" class="text-danger font-small-3">
              {{ promptErrorMessage }}.
            </b-form-text>
            <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
          </validation-provider>
        </b-form-group>
      </b-modal>
    </validation-observer>

    <validation-observer v-slot="{invalid}">
      <b-modal
          ref="promptEditLocalAuthorityDescription"
          v-model="promptEditLocalAuthorityDescription"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          centered
          ok-title="Modifier"
          title="Modifier la description"
          @ok="updateLocalAuthorityDescription">

        <b-form-group label="Description de la collectivité :">
          <validation-provider v-slot="{errors}" name="Description" rules="required|max:65536">
            <b-form-textarea v-model="editingLocalAuthorityDescription" autofocus style="height: 200px"/>
            <b-form-text v-if="promptErrorMessage != null" class="text-danger font-small-3">
              {{ promptErrorMessage }}.
            </b-form-text>
            <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
          </validation-provider>
        </b-form-group>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script type="text/javascript">
import JayUpload from '@/components/global/JayUpload'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {BButton, BCard, BCol, BFormGroup, BFormInput, BFormText, BFormTextarea, BModal, BRow} from 'bootstrap-vue'
import {max, required} from "vee-validate/dist/rules";

extend('required', {...required, message: 'Ce champ est requis'});
extend('max', {...max, message: 'Le champ "{_field_}" doit faire moins de {length} caractères'});

export default {
  components: {
    JayUpload,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BModal,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormText,
  },

  data() {
    return {
      promptErrorMessage: null,
      promptEditLocalAuthorityPhoto: false,
      promptDeleteLocalAuthorityPhoto: false,
      promptEditLocalAuthorityName: false,
      promptEditLocalAuthorityDescription: false,
      editingLocalAuthorityName: null,
      editingLocalAuthorityDescription: null,
    }
  },

  beforeDestroy() {
    this.$refs.promptDeleteLocalAuthorityPhoto.$el.parentNode.removeChild(this.$refs.promptDeleteLocalAuthorityPhoto.$el)
    this.$refs.promptDeleteLocalAuthorityPhoto.$destroy()
    this.$refs.promptEditLocalAuthorityName.$el.parentNode.removeChild(this.$refs.promptEditLocalAuthorityName.$el)
    this.$refs.promptEditLocalAuthorityName.$destroy()
    this.$refs.promptEditLocalAuthorityDescription.$el.parentNode.removeChild(this.$refs.promptEditLocalAuthorityDescription.$el)
    this.$refs.promptEditLocalAuthorityDescription.$destroy()
  },
  computed: {
    user() {
      return this.$store.state.account.user
    },
    localAuthority() {
      // TODO Manage multiple local authority administration
      return this.$store.state.localAuthority.memberOf
    },
    isUserAdmin() {
      let role = this.localAuthority.members.find(member => member.user.id === this.user.id).role
      return role === 'admin'
    },
    getLocalAuthorityPhoto() {
      return this.localAuthority.photo_url
          ? process.env.VUE_APP_BACKEND_URL + '/..' + this.localAuthority.photo_url + "&size=960"
          : require('@/assets/images/default_pictures/local_authority_default.jpg')
    }
  },
  methods: {
    showEditLocalAuthorityPhotoDialog() {
      this.promptErrorMessage = null
      this.promptEditLocalAuthorityPhoto = true
    },
    showDeleteLocalAuthorityPhotoDialog() {
      this.promptErrorMessage = null
      this.promptDeleteLocalAuthorityPhoto = true
    },
    showEditLocalAuthorityNameDialog() {
      this.promptErrorMessage = null
      this.editingLocalAuthorityName = this.localAuthority.name
      this.promptEditLocalAuthorityName = true
    },
    showEditLocalAuthorityDescriptionDialog() {
      this.promptErrorMessage = null
      this.editingLocalAuthorityDescription = this.localAuthority.description
      this.promptEditLocalAuthorityDescription = true
    },
    updateLocalAuthorityPhoto(photo) {
      this.$loading(true)
      this.$store.dispatch("localAuthority/updateInformation", {
        'id': this.localAuthority.id,
        'photo': photo.id
      }).then(() => {
        this.$notify({
          time: 4000,
          title: "Modification",
          text: "La photo a bien été mise à jour",
          color: "primary"
        })
      }).catch(() => {
        this.$notify({
          time: 4000,
          title: "Erreur",
          text: "Une erreur technique est survenue lors de la mise à jour de la photo, veuillez réessayer plus tard",
          color: "danger"
        })
      }).finally(() => this.$loading(false))
    },
    deleteLocalAuthorityPhoto() {
      this.$loading(true)
      this.$store.dispatch("localAuthority/updateInformation", {
        'id': this.localAuthority.id,
        'photo': null
      }).then(() => {
        this.$notify({
          time: 4000,
          title: "Suppression",
          text: "La photo a bien été supprimée",
          color: "primary"
        })
      }).catch(() => {
        this.$notify({
          time: 4000,
          title: "Erreur",
          text: "Une erreur technique est survenue lors de la suppression la photo, veuillez réessayer plus tard",
          color: "danger"
        })
      }).finally(() => this.$loading(false))
    },
    updateLocalAuthorityDescription() {
      this.$loading()
      this.$store.dispatch("localAuthority/updateInformation", {
        id: this.localAuthority.id,
        description: this.editingLocalAuthorityDescription
      }).then(() => {
        this.$notify({
          time: 4000,
          title: "Modification",
          text: "La description de la collectivité a bien été mise à jour",
          color: "primary"
        })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.promptErrorMessage = "La description choisie est invalide (trop courte, trop longue ou emploi de caractère invalide)"
        } else {
          this.promptErrorMessage = "Erreur technique, veuillez rééssayer plus tard"
        }
        this.promptEditLocalAuthorityDescription = true
      }).finally(() => this.$loading(false))
    },
    updateLocalAuthorityName() {
      this.$loading(true)
      this.$store.dispatch("localAuthority/updateInformation", {
        'id': this.localAuthority.id,
        'name': this.editingLocalAuthorityName
      }).then(() => {
        this.$notify({
          time: 4000,
          title: "Modification",
          text: "Le nom de la collectivité a bien été mis à jour",
          color: "primary"
        })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.promptErrorMessage = "Le nom choisi est invalide (trop court, trop long ou emploi de caractère invalide)"
        } else {
          this.promptErrorMessage = "Erreur technique, veuillez rééssayer plus tard"
        }
        this.promptEditLocalAuthorityName = true
      }).finally(() => this.$loading(false))
    },
  },
}
</script>
