<template lang="html">
  <div class="local-authority" v-on:scroll="scroll">
    <JayaTitle class="mb-2"
        classType="page-title"
        titleName="Administration de la collectivité"/>
    <b-row class="w-100 m-0 p-0 ">
      <b-col class="col-12 col-lg-6 p-0 pr-lg-50 mb-2">
        <admin-profile/>
      </b-col>
      <b-col class="col-12 col-lg-6 p-0 pl-lg-50 mb-2">
        <admin-members/>
      </b-col>
      <b-col class="col-12 p-0">
        <LocalAuthorityDataCard/>
      </b-col>
      <b-col class="col-12 col-lg-6 p-0 pr-lg-50 mb-1">
        <LocalAuthorityHelpSheets/>
      </b-col>
      <b-col class="col-12 col-lg-6 p-0 pl-lg-50 mb-1">
        <admin-events/>
      </b-col>
    </b-row>
  </div>
</template>

<script type="text/javascript">
import adminProfile from '@/components/local-authorities/administration/LocalAuthorityAdministrationProfile'
import adminMembers from '@/components/local-authorities/administration/LocalAuthorityAdministrationMembers'
import LocalAuthorityHelpSheets from '@/components/local-authorities/administration/LocalAuthorityHelpSheets.vue'
import adminEvents from '@/components/local-authorities/administration/LocalAuthorityAdministrationEvents'
import TextBlock from '@/components/global/JayaTextBlock.vue'
import JayaTitle from '@/components/global/JayaTitle.vue'
import LocalAuthorityDataCard from '@/components/local-authorities/administration/LocalAuthorityDataCard.vue'
import {BCol, BRow} from 'bootstrap-vue'

export default {
  components: {
    adminProfile,
    adminMembers,
    adminEvents,
    LocalAuthorityHelpSheets,
    TextBlock,
    LocalAuthorityDataCard,
    JayaTitle,
    BCol,
    BRow,
  },
  methods: {
    scroll(event) {
      this.$refs.adminEvents.scroll(event)
    }
  }
}
</script>

<style lang="scss">
.router-view {
  padding-bottom: 0 !important;
}

.local-authority {
  width: 100%;

  .local-authority-image {
    position: relative;

    .change-local-authority-image {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .icon {
    height: 30px;
  }

  .bottom-items {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
