<template>
  <b-card sub-title="Liste des fiches à destination de vos abonnés" title="Fiches pratiques">
    <app-collapse v-if="helpSheets.length>0">
      <app-collapse-item
          v-for="sheet in helpSheets"
          :key="sheet.id"
          :ref="'helpSheetCollapse' + sheet.id"
          title="">
        <div slot="header" class="overflow-auto">
          <h3 class="text-secondary font-medium-2 mb-0">{{ sheet.title }}</h3>
          <h1>{{ sheet.sheetModified }}</h1>
          <p v-if="sheet.newSheet || modifiedSheetsIds.includes(sheet.id)" class="font-small-2 mb-0 text-danger">
            (modifications non enregistrées)
          </p>
        </div>
        <EditableHelpSheet
            :sheet="sheet"
            @cancelModifications="cancelModifications(sheet.id)"
            @deleted="removeHelpSheet"
            @reload="setSheetModifyed(sheet.id)"/>
      </app-collapse-item>
    </app-collapse>
    <p v-else class="text-center text-warning mt-3"> Aucune fiche n'a encore été créée </p>
    <div class="d-flex justify-content-center">
      <b-button
          v-if="!creatingSheet"
          class="col-12 col-lg-6 mt-1"
          variant="primary"
          @click="newHelpSheet">
        <feather-icon icon="ToolIcon"/>
        Nouvelle fiche
      </b-button>
    </div>
  </b-card>

</template>

<script>
import EditableHelpSheet from '@/components/help-sheets/EditableHelpSheet.vue'
import {BButton, BCard,} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";

export default {
  name: 'LocalAuthorityHelpSheets',

  components: {
    EditableHelpSheet,
    BCard,
    BButton,
    AppCollapse,
    AppCollapseItem,
  },

  data() {
    return {
      helpSheets: [],
      modifiedSheetsIds: [],
      creatingSheet: false,
      helpSheetCollapseMainKey: 0,
    }
  },
  mounted() {
    this.getHelpSheets()
  },
  computed: {
    localAuthority() {
      // TODO Manage multiple local authority administration
      return this.$store.state.localAuthority.memberOf
    },
  },
  methods: {
    getHelpSheets() {
      this.helpSheets = []
      this.$store.dispatch("helpSheet/search", {"local_authority": this.localAuthority.id})
          .then(response_sheets => {
            var sheets = response_sheets.data
            for (const sheet of sheets) {
              this.$store.dispatch("helpSheet/get", sheet.id)
                  .then(response_sheet => {
                    this.helpSheets.push(response_sheet.data)
                  })
            }
          })
          .catch(() => {
            this.helpSheets = []
          })
    },
    newHelpSheet() {
      let newHelpSheet
      newHelpSheet = {
        id: this.helpSheets.length + 1,
        title: "Fiche en création",
        introduction: "Introduction",
        paragraphs: [],
        local_authority: this.localAuthority.id,
        photos: [],
        newSheet: true
      }
      this.helpSheets.unshift(newHelpSheet)
      this.creatingSheet = true
    },
    setSheetModifyed(sheedId) {
      this.modifiedSheetsIds.push(sheedId)
    },
    cancelModifications(sheedId) {
      this.modifiedSheetsIds = this.modifiedSheetsIds.filter(id => id !== sheedId)
      this.creatingSheet = false
      this.getHelpSheets()
    },
    removeHelpSheet(id) {
      this.helpSheets = this.helpSheets.filter(e => {
        return e.id !== id
      })
    },
  }
}
</script>
