<template lang="html">
  <div>
    <b-card sub-title="Evènements et lieux" title="Autour de moi">
      <b-tabs class="mt-2" fill>
        <b-tab title="A venir" @click="comingEvents">
          <div class="d-flex justify-content-center flex-column align-items-center">
            <p v-if="elementList.length === 0" class="text-center text-warning mb-0 mt-1">Cette catégorie est vide.</p>

            <b-button
                class="col-12 col-lg-6 mt-1"
                variant="primary"
                @click="showCreateEventDialog">
              <feather-icon icon="ToolIcon"/>
              Nouvel Evènement
            </b-button>
          </div>
        </b-tab>
        <b-tab title="Passés" @click="pastEvents">
        </b-tab>
        <b-tab title="Lieux publics" @click="getPlaces">
          <div class="d-flex justify-content-center">
            <b-button
                class="col-12 col-lg-6 mt-1"
                variant="primary"
                @click="showCreatePlaceDialog">
              <feather-icon icon="ToolIcon"/>
              Nouveau Lieu
            </b-button>
          </div>
        </b-tab>
      </b-tabs>

      <app-collapse v-if="elementList.length !== 0">
        <app-collapse-item v-for="item in elementList" :key="'element-' + item.id"
            :ref="'helpSheetCollapse' + item.id" title="">
          <div slot="header" class="overflow-auto">
            <h2 class="text-secondary font-medium-2 mb-0">{{ item.title }}</h2>
          </div>
          <div class="mb-1">
            <img :alt="'Photo de l\'évènement ' + item.title"
                :src="getPhoto(item)"
                style="object-fit: contain; height:100%; width:100%; max-height: 200px">
            <div class="d-flex justify-content-center align-items-center">
              <b-button
                  v-if="isCreator(item)"
                  class="btn-icon"
                  variant="flat-primary"
                  @click="showEditPhotoDialog(item)">
                <feather-icon icon="EditIcon"/>
              </b-button>
              <b-button
                  v-if="isCreator(item) && item.photo_url"
                  class="btn-icon"
                  variant="flat-danger"
                  @click="showDeletePhotoDialog(item)">
                <feather-icon icon="TrashIcon"/>
              </b-button>
            </div>
          </div>

          <p v-if="!isCreator(item)" class="text-warning text-center">
            Vous n'êtes pas le créateur de l'évènement ou du lieu
          </p>

          <!-- Créateur -->
          <div v-if="!isCreator(item)" class="font-medium-3 font-weight-bold">Créateur</div>
          <p v-if="!isCreator(item)" class="overflow-auto text-justify">{{ item.creator_info.name }}</p>

          <!-- Titre -->
          <div class="d-flex align-items-center">
            <span class="font-medium-3 font-weight-bold">Titre</span>
            <b-button
                v-if="isCreator(item)"
                class="btn-icon"
                variant="flat-primary"
                @click="showEditTitleDialog(item)">
              <feather-icon icon="EditIcon"/>
            </b-button>
          </div>
          <p class="overflow-auto">{{ item.title }}</p>

          <!-- Description -->
          <div class="d-flex align-items-center">
            <span class="font-medium-3 font-weight-bold">Description</span>
            <b-button
                v-if="isCreator(item)"
                class="btn-icon"
                variant="flat-primary"
                @click="showEditDescriptionDialog(item)">
              <feather-icon icon="EditIcon"/>
            </b-button>
          </div>
          <p v-for="(part,id) in item.description.split('\n')" :key="id" class="m-0 mt-25 overflow-auto text-justify" v-html="paragraphWithLink(part)"/>

          <!-- Dates -->
          <div v-if="item.start_date && item.end_date">
            <div class="d-flex align-items-center">
              <span class="font-medium-3 font-weight-bold">Dates</span>
              <b-button
                  v-if="isCreator(item)"
                  class="btn-icon"
                  variant="flat-primary"
                  @click="showEditEventDatesDialog(item)">
                <feather-icon icon="EditIcon"/>
              </b-button>
            </div>
            <p class="overflow-auto text-justify">
              {{ 'Du ' + item.start_date.toLocaleString() + ' au ' + item.end_date.toLocaleString() }}</p>
          </div>

          <!-- Adresse -->
          <div class="d-flex align-items-center">
            <span class="font-medium-3 font-weight-bold">Adresse</span>
            <b-button
                v-if="isCreator(item)"
                class="btn-icon"
                variant="flat-primary"
                @click="showEditLocation(item)">
              <feather-icon icon="EditIcon"/>
            </b-button>
          </div>
          <p class="overflow-auto mb-50">{{ item.location_address }}</p>
          <p v-if="item.location_point != null" class="overflow-auto text-justify">
            {{ 'Position géographique: ' + item.location_point[0] + ', ' + item.location_point[1] }}
          </p>

          <!-- Type de lieu -->
          <div v-if="item.type">
            <div class="d-flex align-items-center">
              <span class="font-medium-3 font-weight-bold">Type de lieu</span>
              <b-button
                  v-if="isCreator(item)"
                  class="btn-icon"
                  variant="flat-primary"
                  @click="showEditPlaceTypeDialog(item)">
                <feather-icon icon="EditIcon"/>
              </b-button>
            </div>
            <p class="overflow-auto">{{ typeUnparse(item.type) }}</p>
          </div>

          <!-- Participants -->
          <div v-if="!item.type">
            <div class="d-flex align-items-center">
              <span class="font-medium-3 font-weight-bold">Participants</span>
              <b-button
                  v-if="isCreator(item)"
                  class="btn-icon"
                  variant="flat-primary"
                  @click="showEditNmaxParticipants(item)">
                <feather-icon icon="EditIcon"/>
              </b-button>
            </div>
            <p class="overflow-auto mb-50">{{ 'Nombre maximal de participants: ' + printNmaxParticipants(item) }}</p>
            <p class="overflow-auto">{{ 'Inscrit(s): ' + item.participants_count.toLocaleString() }}</p>
          </div>

          <!-- Partenaires -->
          <div v-if="!item.type">
            <div class="d-flex align-items-center">
              <span class="font-medium-3 font-weight-bold">Partenaires</span>
              <b-button
                  v-if="isCreator(item)"
                  class="btn-icon"
                  variant="flat-primary"
                  @click="showAddPartnerDialog(item)">
                <feather-icon icon="PlusIcon"/>
              </b-button>
            </div>

            <b-list-group v-if="item.partners.length > 0" class="mt-0" flush>
              <b-list-group-item
                  v-for="partner in item.partners"
                  :key="'partner-' + partner.id"
                  class="d-flex align-items-center p-0">
                <span>{{ partner.name }}</span>
                <b-button
                    v-if="isCreator(item)"
                    class="btn-icon ml-auto"
                    variant="flat-danger"
                    @click="showDeletePartnerDialog(item, partner)">
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </b-list-group-item>
            </b-list-group>
            <p v-else class="overflow-auto mb-50 text-info font-small-3 font-italic">Aucun partenaire de l'évènement</p>
          </div>

          <!-- Supprimer -->
          <div v-if="isCreator(item)"
              class="d-flex justify-content-center">
            <b-button
                class="col-12 col-lg-6 mt-1"
                size="sm"
                variant="danger"
                @click="showDeleteElementDialog(item)">
              <feather-icon icon="TrashIcon"/>
              Supprimer
            </b-button>
          </div>
        </app-collapse-item>
      </app-collapse>
    </b-card>

    <b-modal
        id="Popupquestion"
        ref="popUpEditLocationPoint"
        v-model="popUpEditLocationPoint"
        body-class="p-0"
        centered
        hide-footer
        size="xl"
        title="Localisation">
      <div style="width: 100%; height: calc(100vh - 200px)">
        <l-map ref="map"
            :center="mapCenter"
            :zoom="mapZoom"
            @update:bounds="updateMapBounds"
            @update:center="updateMapCenter"
            @update:zoom="updateMapZoom">
          <l-tile-layer :url="mapUrl"/>
          <l-marker :draggable="true" :lat-lng.sync="markerPosition">
            <l-icon :key="0" :icon-anchor="[16, 48]" :icon-size="[30, 60]">
              <img alt="" src="@/assets/images/icons/other/default_location_marker.svg">
            </l-icon>
          </l-marker>
          <v-geosearch ref="geosearch" v-model="editingElement" :options="geosearchOptions"/>
          <l-control position="bottomright">
            <b-button
                class="m-25"
                variant="primary"
                @click="selectLocationPoint">
              Valider
            </b-button>
            <b-button
                class="m-25"
                variant="secondary"
                @click="popUpEditLocationPoint = false">
              Annuler
            </b-button>
          </l-control>
        </l-map>
      </div>
    </b-modal>

    <JayUpload :active.sync="promptAddPhoto"
        title="Ajouter une photo"
        @on-success="addPhoto"/>

    <JayUpload :active.sync="promptEditPhoto"
        title="Changer de photo"
        @on-success="updatePhoto"/>

    <!-- Delete Photo -->
    <b-modal
        ref="promptDeletePhoto"
        v-model="promptDeletePhoto"
        cancel-title="Annuler"
        centered
        ok-title="Supprimer"
        title="Suppression de la photo"
        @ok="deletePhoto">
      <p> Êtes-vous sûr de vouloir supprimer la photo ?</p>
    </b-modal>

    <!-- Edit Title -->
    <b-modal
        ref="promptEditTitle"
        v-model="promptEditTitle"
        cancel-title="Annuler"
        centered
        ok-title="Modifier"
        title="Modifier le titre"
        @ok="updateTitle">
      <validation-provider v-slot="{errors}" immediate name="Titre" rules="max:150">
        <b-form-input v-model="editingTitle" placeholder="Titre"/>
        <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
        <b-form-text v-if="promptErrorMessage != null" class="text-danger">
          {{ promptErrorMessage }}
        </b-form-text>
      </validation-provider>
    </b-modal>

    <!-- Edit Description -->
    <b-modal
        ref="promptEditDescription"
        v-model="promptEditDescription"
        cancel-title="Annuler"
        centered
        ok-title="Modifier"
        title="Modifier la description"
        @ok="updateDescription">
      <validation-provider v-slot="{errors}" immediate name="Description" rules="max:65536">
        <b-form-textarea v-model="editingDescription" style="height: 200px"/>
        <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
        <b-form-text v-if="promptErrorMessage != null" class="text-danger">
          {{ promptErrorMessage }}
        </b-form-text>
      </validation-provider>
    </b-modal>

    <!-- Edit Location -->
    <b-modal
        ref="promptEditLocation"
        v-model="promptEditLocation"
        cancel-title="Annuler"
        centered
        ok-title="Valider"
        title="Modifier l'adresse"
        @ok="updateLocation">

      <vue-simple-suggest
          v-model="editingLocationAddress"
          :debounce="200"
          :filter-by-query="false"
          :list="geosearch"
          :max-suggestions="3"
          :min-length="3"
          :nullable-select="true"
          :prevent-submit="true"
          display-attribute="label"
          placeholder="Adresse de l'évènement"
          value-attribute="raw.place_id"
          @select="onAddressSelected"/>
      <b-form-text v-if="promptErrorMessage != null" class="text-danger m">
        {{ promptErrorMessage }}
      </b-form-text>

      <div v-if="editingLocationPoint != null" class="d-flex align-items-center mt-1 mb-1">
        <span>
          Position géographique: {{ editingLocationPoint[0] + ', ' + editingLocationPoint[1] }}
        </span>
        <b-button
            class="btn-icon ml-auto"
            variant="flat-danger"
            @click="editingLocationPoint = null">
          <feather-icon icon="TrashIcon"/>
        </b-button>
      </div>


      <div class="d-flex justify-content-center">
        <b-button
            class=""
            variant="outline-primary"
            @click="showEditLocationPoint">
          Sélectionner l'emplacement sur une carte
        </b-button>
      </div>
    </b-modal>

    <!-- Add Partner -->
    <b-modal
        ref="promptAddPartner"
        v-model="promptAddPartner"
        cancel-title="Annuler"
        centered
        ok-title="Ajouter"
        title="Ajouter un partenaire"
        @ok="addPartner">

      <vue-simple-suggest
          v-model="editingPartnerName"
          :filter-by-query="true"
          :list="partnerNameList">
      </vue-simple-suggest>
      <b-form-text v-if="promptErrorMessage != null" class="text-danger">
        {{ promptErrorMessage }}
      </b-form-text>
    </b-modal>

    <!-- Delete Partner -->
    <b-modal
        ref="promptDeletePartner"
        v-model="promptDeletePartner"
        cancel-title="Annuler"
        centered
        ok-title="Supprimer"
        ok-variant="danger"
        title="Supprimer un partenaire"
        @ok="deletePartner()">
      <p v-if="editingPartner"> Êtes-vous sûr de vouloir supprimer <strong>{{ editingPartner.name }}</strong> comme
        partenaire ?</p>
    </b-modal>

    <!-- Delete Element -->
    <b-modal
        ref="promptDeleteElement"
        v-model="promptDeleteElement"
        cancel-title="Annuler"
        centered
        ok-title="Supprimer"
        ok-variant="danger"
        @ok="deleteElement">
      <template v-if="editingElement" #modal-title>
        {{ editingElement.type ? 'Supprimer le lieu ' : 'Supprimer l\'évènement' }}
      </template>
      <p v-if="editingElement"> Êtes-vous sûr de vouloir supprimer
        {{ editingElement.type ? 'le lieu ' : 'l\'évènement ' }}: <strong>{{ editingElement.title }}</strong> ?</p>
    </b-modal>

    <!-- Event creation -->
    <validation-observer v-slot="{passes}">
      <b-modal
          ref="popUpCreateEvent"
          v-model="popUpCreateEvent"
          cancel-title="Annuler"
          centered
          ok-title="Créer l'évènement"
          title="Ajouter un évènement"
          @ok.prevent="passes(createEvent)">

        <b-form>
          <!-- Title -->
          <div>
            <b-form-group label="Titre">
              <validation-provider v-slot="{errors}" name="Titre" rules="required|max:150">
                <b-input-group>
                  <b-form-input v-model="editingTitle"
                      autofocus
                      placeholder="Titre"/>
                </b-input-group>
                <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
              </validation-provider>
            </b-form-group>
          </div>

          <!-- Description -->
          <div>
            <b-form-group label="Description">
              <validation-provider v-slot="{errors}" name="Description" rules="required|max:65536">
                <b-input-group>
                  <b-form-textarea v-model="editingDescription"
                      placeholder="Description"/>
                </b-input-group>
                <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
              </validation-provider>
            </b-form-group>
          </div>

          <!-- Dates -->
          <div>
            <b-form-group label="Dates">
              <validation-provider v-slot="{errors}" rules="required">
                <b-input-group>
                  <flat-pickr v-model="editingEventStartDate"
                      :config="configStartDateTimePicker"
                      class="form-control"
                      placeholder="Début"
                      v-on:on-close="checkDates"/>
                </b-input-group>
                <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
              </validation-provider>

              <validation-provider v-slot="{errors}" rules="required">
                <b-input-group>

                  <flat-pickr v-model="editingEventEndDate"
                      :config="configEndDateTimePicker"
                      class="form-control"
                      placeholder="Fin"
                      v-on:on-close="checkDates"/>
                </b-input-group>
                <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
              </validation-provider>
            </b-form-group>
          </div>

          <!-- Adresse -->
          <div>
            <b-form-group label="Adresse">
              <validation-provider v-slot="{errors}" name="Adresse" rules="required">
                <vue-simple-suggest
                    v-model="editingLocationAddress"
                    :debounce="200"
                    :filter-by-query="false"
                    :list="geosearch"
                    :max-suggestions="3"
                    :min-length="3"
                    :nullable-select="true"
                    :prevent-submit="true"
                    display-attribute="label"
                    value-attribute="raw.place_id"
                    @select="onAddressSelected"/>
                <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
              </validation-provider>
              <div v-if="editingLocationPoint != null" class="d-flex align-items-center">
                <b-form-text class="overflow-auto text-justify">
                  {{ 'Position géographique: ' + editingLocationPoint[0] + ', ' + editingLocationPoint[1] }}
                </b-form-text>
                <b-button
                    class="btn-icon ml-auto"
                    variant="flat-danger"
                    @click="editingLocationPoint = null">
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </div>

              <b-button
                  class="mt-50 col-12"
                  variant="outline-primary"
                  @click="showEditLocationPoint">
                <feather-icon icon="MapPinIcon"/>
                Sélectionner l'emplacement sur la carte
              </b-button>
            </b-form-group>
          </div>

          <!-- Photo de l'évènement -->
          <div class="mt-1">
            <b-form-group label="Photo de l'évènement">
              <div class="d-flex flex-column align-items-center">
                <img
                    v-if="addingPhoto"
                    :alt="'Image de l\'évènement ' + editingTitle"
                    :src="addingPhoto.url"
                    style="max-width: 100%; max-height: 250px; margin-bottom: 5px;"/>
                <b-button
                    class="col-12"
                    variant="outline-secondary"
                    @click="promptAddPhoto = true">
                  <feather-icon icon="MapPinIcon"/>
                  {{ (addingPhoto ? 'Modifier la photo' : 'Ajouter une photo') }}
                </b-button>
              </div>
            </b-form-group>
          </div>

          <!-- Nombre de participant -->
          <div>
            <b-form-group label="Participants">
              <validation-provider
                  v-slot="{errors}"
                  :rules="`${!illimitedParticipants ? 'required|min_value:1|max_value:32767' : ''}`"
                  name="Description">
                <b-input-group>
                  <b-form-input
                      v-model="nmax_participants"
                      :disabled="illimitedParticipants"
                      placeholder="Nombre maximum"
                  />
                </b-input-group>
                <b-form-text
                    class="text-danger">
                  {{ errors[0] }}
                </b-form-text>
              </validation-provider>
              <b-form-checkbox
                  v-model="illimitedParticipants"
                  class="mt-1">
                <span class="ml-25">Nombre de participants illimité</span>
              </b-form-checkbox>
            </b-form-group>
          </div>

          <!-- Error message -->
          <div v-if="promptErrorMessage != null">
            <b-form-text
                class="text-danger mt-1">
              {{ promptErrorMessage }}
            </b-form-text>
          </div>
        </b-form>
      </b-modal>
    </validation-observer>

    <!-- Edit Event Dates -->
    <b-modal
        ref="promptEditEventDates"
        v-model="promptEditEventDates"
        cancel-title="Annuler"
        centered
        ok-title="Valider"
        title="Modifier les dates"
        @ok="updateEventDates">
      <div>
        <b-form-group class="" label="Dates">
          <validation-provider v-slot="{errors}" rules="required">
            <b-input-group>
              <flat-pickr v-model="editingEventStartDate"
                  :config="configStartDateTimePicker"
                  class="form-control"
                  placeholder="Début"
                  v-on:on-close="checkDates"
              />
            </b-input-group>
            <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
          </validation-provider>

          <validation-provider v-slot="{errors}" rules="required">
            <b-input-group>
              <flat-pickr v-model="editingEventEndDate"
                  :config="configEndDateTimePicker"
                  class="form-control"
                  placeholder="Fin"
                  v-on:on-close="checkDates"
              />
            </b-input-group>
            <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
          </validation-provider>

        </b-form-group>
      </div>
    </b-modal>

    <!-- Edit Number of Participant -->
    <b-modal
        ref="promptEditNmaxParticipants"
        v-model="promptEditNmaxParticipants"
        cancel-title="Annuler"
        centered
        ok-title="Valider"
        title="Modifier le nombre de participants"
        @ok="updateNmaxParticipants">
      <div>
        <b-form-group label="Participants">
          <validation-provider
              v-slot="{errors}"
              :rules="`${!illimitedParticipants ? 'required|min_value:1|max_value:32767' : ''}`"
              name="Description">
            <b-input-group>
              <b-form-input
                  v-model="nmax_participants"
                  :disabled="illimitedParticipants"
                  placeholder="Nombre maximum"
              />
            </b-input-group>
            <b-form-text
                class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
          <b-form-checkbox
              v-model="illimitedParticipants"
              class="mt-1">
            <span class="ml-25">Nombre de participants illimité</span>
          </b-form-checkbox>
        </b-form-group>
      </div>
    </b-modal>

    <!-- Place creation -->
    <validation-observer v-slot="{passes}">
      <b-modal
          ref="popUpCreatePlace"
          v-model="popUpCreatePlace"
          cancel-title="Annuler"
          centered
          ok-title="Créer le lieu"
          title="Ajouter un lieu"
          @ok.prevent="passes(createPlace)">

        <b-form>
          <!-- Title -->
          <div>
            <b-form-group label="Titre">
              <validation-provider v-slot="{errors}" name="Titre" rules="required|max:150">
                <b-input-group>
                  <b-form-input v-model="editingTitle"
                      autofocus
                      placeholder="Titre"/>
                </b-input-group>
                <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
              </validation-provider>
            </b-form-group>
          </div>

          <!-- Description -->
          <div>
            <b-form-group label="Description">
              <validation-provider v-slot="{errors}" name="Description" rules="required|max:65536">
                <b-input-group>
                  <b-form-textarea v-model="editingDescription"
                      placeholder="Description"/>
                </b-input-group>
                <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
              </validation-provider>
            </b-form-group>
          </div>

          <!-- Type de lieu -->
          <div>
            <b-form-group label="Type de lieu">
              <validation-provider v-slot="{errors}" immediate rules="required">
                <b-input-group>
                  <b-form-select
                      v-model="editingPlaceType">
                    <option :value="null" disabled>Type de lieu</option>
                    <option v-for="(type, index) in placeType" :key="'type-' + index">{{ type.name }}</option>
                  </b-form-select>
                </b-input-group>
                <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
              </validation-provider>
            </b-form-group>
          </div>

          <!-- Adresse -->
          <div>
            <b-form-group label="Adresse">
              <validation-provider v-slot="{errors}" name="Adresse" rules="required">
                <vue-simple-suggest
                    v-model="editingLocationAddress"
                    :debounce="200"
                    :filter-by-query="false"
                    :list="geosearch"
                    :max-suggestions="3"
                    :min-length="3"
                    :nullable-select="true"
                    :prevent-submit="true"
                    display-attribute="label"
                    value-attribute="raw.place_id"
                    @select="onAddressSelected"/>
                <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
              </validation-provider>
              <div v-if="editingLocationPoint != null" class="d-flex align-items-center">
                <b-form-text class="overflow-auto text-justify">
                  {{ 'Position géographique: ' + editingLocationPoint[0] + ', ' + editingLocationPoint[1] }}
                </b-form-text>
                <b-button
                    class="btn-icon ml-auto"
                    variant="flat-danger"
                    @click="editingLocationPoint = null">
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </div>

              <b-button
                  class="mt-50 col-12"
                  variant="outline-primary"
                  @click="showEditLocationPoint">
                <feather-icon icon="MapPinIcon"/>
                Sélectionner l'emplacement sur la carte
              </b-button>
            </b-form-group>
          </div>

          <!-- Photo du lieu -->
          <div class="mt-1">
            <b-form-group label="Photo de l'évènement">
              <div class="d-flex flex-column align-items-center">
                <img v-if="addingPhoto" :alt="'Image du lieu: '+ editingTitle"
                    :src="addingPhoto.url"
                    style="max-width: 100%; max-height: 250px; margin-bottom: 5px;"/>
                <b-button
                    class="col-12"
                    variant="outline-secondary"
                    @click="promptAddPhoto = true">
                  <feather-icon icon="MapPinIcon"/>
                  {{ (addingPhoto ? 'Modifier la photo' : 'Ajouter une photo') }}
                </b-button>
              </div>
            </b-form-group>
          </div>

          <!-- Error message -->
          <div v-if="promptErrorMessage != null">
            <b-form-text
                class="text-danger mt-1">
              {{ promptErrorMessage }}
              test
            </b-form-text>
          </div>
        </b-form>
      </b-modal>
    </validation-observer>

    <!-- Type de lieu -->
    <b-modal
        ref="promptEditPlaceType"
        v-model="promptEditPlaceType"
        cancel-title="Annuler"
        centered
        ok-title="Valider"
        title="Modifier le type de lieu"
        @ok="updatePlaceType">
      <div>
        <b-form-group label="Type de lieu">
          <validation-provider v-slot="{errors}" immediate rules="required">
            <b-input-group>
              <b-form-select
                  v-model="editingPlaceType">
                <option :value="null" disabled>Type de lieu</option>
                <option v-for="(type, index) in placeType" :key="'type-' + index">{{ type.name }}</option>
              </b-form-select>
            </b-input-group>
            <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
          </validation-provider>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import JayUpload from '@/components/global/JayUpload'

import {LControl, LIcon, LMap, LMarker, LTileLayer} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import {OpenStreetMapProvider} from 'leaflet-geosearch'
import VGeosearch from 'vue2-leaflet-geosearch'
//fix icon in leaflet
import {Icon} from 'leaflet';
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'

import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {max, max_value, min_value, required} from "vee-validate/dist/rules";

import moment from 'moment'

import DOMPurify from 'dompurify';

import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";

import {
  BButton,
  BCard,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormText,
  BFormTextarea,
  BInputGroup,
  BListGroup,
  BListGroupItem,
  BModal,
  BTab,
  BTabs,
} from "bootstrap-vue";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


extend('required', {...required, message: 'Ce champ est requis'});
extend('max', {...max, message: 'Le champ "{_field_}" doit faire moins de {length} caractères'});
extend('min_value', {...min_value, message: 'Valeurs inférieures à {min} interdites'});
extend('max_value', {...max_value, message: 'Valeurs supérieures à {max} interdites'});

export default {
  components: {
    JayUpload,
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LIcon,
    VGeosearch,
    flatPickr,
    VueSimpleSuggest,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    BButton,
    BCard,
    BTabs,
    BTab,
    BModal,
    BListGroup,
    BListGroupItem,
    BForm,
    BFormText,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
  },

  data() {
    return {
      // Map settings
      mapUrl: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      mapZoom: 14,
      mapCenter: [0, 0],
      markerPosition: [0, 0],
      mapBounds: null,
      geosearchOptions: {
        provider: new OpenStreetMapProvider(),
        showMarker: false,
        showPopup: false,
        retainZoomLevel: false,
        animateZoom: true,
        autoClose: true,
        searchLabel: 'Entrez une adresse',
        style: 'button'
      },
      geosearchSetup: false,

      // Date time settings
      configStartDateTimePicker: {
        enableTime: true,
        time_24hr: true,
        minDate: null,
        maxDate: null
      },
      configEndDateTimePicker: {
        enableTime: true,
        time_24hr: true,
        minDate: null,
        maxDate: null
      },

      // List scroll and editing status
      showPastEvents: false,
      showPlaces: false,
      editingPartner: null,
      waitScroll: false,
      elementListLimit: 20,
      elementListOffset: 0,
      elementListNext: true,

      //Place or Event attributes
      editingElement: null,
      editingTitle: null,
      editingLocationPoint: null,
      editingLocationAddress: null,
      editingDescription: null,
      editingPartnerName: null,
      addingPhoto: null,
      elementList: [],
      partnerList: [],

      promptAddPhoto: false,
      promptEditPhoto: false,
      promptDeletePhoto: false,
      promptEditTitle: false,
      promptEditDescription: false,
      promptEditLocation: false,


      popUpEditLocationPoint: false,
      promptAddPartner: false,
      promptDeletePartner: false,
      promptDeleteElement: false,
      promptErrorMessage: null,

      // Events attributes settings
      editingEventStartDate: null,
      editingEventEndDate: null,
      popUpCreateEvent: false,
      promptEditEventDates: false,
      illimitedParticipants: true,
      nmax_participants: null,
      minNmaxParticipants: 0,
      promptEditNmaxParticipants: false,

      // Places attributes settings
      editingPlaceType: null,
      popUpCreatePlace: false,
      promptEditPlaceType: false,
    }
  },

  created() {
    // Get events
    this.elementList = []
    this.fetchNextElements()

    // Get partners
    this.$store.dispatch("localAuthority/fetchLocalAuthorities")
        .then(partners => {
          // Get the list of potentials partner by getting all local authorities and removing the current one
          this.partnerList = partners.filter(partner => partner.id !== this.localAuthority.id)
        })
        .catch(() => {
          this.$notify({
            time: 4000,
            title: "Erreur technique",
            text: "Une erreur est survenue lors de la récupération des partenaires.",
            color: "danger"
          })
        })
  },
  beforeDestroy() {
    this.$refs.promptEditTitle.$el.parentNode.removeChild(this.$refs.promptEditTitle.$el)
    this.$refs.promptEditTitle.$destroy()
    this.$refs.promptDeletePhoto.$el.parentNode.removeChild(this.$refs.promptDeletePhoto.$el)
    this.$refs.promptDeletePhoto.$destroy()
    this.$refs.promptEditDescription.$el.parentNode.removeChild(this.$refs.promptEditDescription.$el)
    this.$refs.promptEditDescription.$destroy()
    this.$refs.promptEditLocation.$el.parentNode.removeChild(this.$refs.promptEditLocation.$el)
    this.$refs.promptEditLocation.$destroy()
    this.$refs.promptAddPartner.$el.parentNode.removeChild(this.$refs.promptAddPartner.$el)
    this.$refs.promptAddPartner.$destroy()
    this.$refs.promptDeletePartner.$el.parentNode.removeChild(this.$refs.promptDeletePartner.$el)
    this.$refs.promptDeletePartner.$destroy()
    this.$refs.promptDeleteElement.$el.parentNode.removeChild(this.$refs.promptDeleteElement.$el)
    this.$refs.promptDeleteElement.$destroy()

    this.$refs.promptEditEventDates.$el.parentNode.removeChild(this.$refs.promptEditEventDates.$el)
    this.$refs.promptEditEventDates.$destroy()
    this.$refs.promptEditNmaxParticipants.$el.parentNode.removeChild(this.$refs.promptEditNmaxParticipants.$el)
    this.$refs.promptEditNmaxParticipants.$destroy()


    this.$refs.promptEditPlaceType.$el.parentNode.removeChild(this.$refs.promptEditPlaceType.$el)
    this.$refs.promptEditPlaceType.$destroy()
  },
  computed: {
    user() {
      return this.$store.state.account.user
    },
    localAuthority() {
      // TODO Manage multiple local authority administration
      return this.$store.state.localAuthority.memberOf
    },
    isUserAdmin() {
      let role = this.localAuthority.members.find(member => member.user.id === this.user.id).role
      return role === 'admin'
    },
    partnerNameList() {
      return this.partnerList.map(partner => partner.name)
    },
    typeParsing() {
      return this.placeType.find(item => item.name === this.editingPlaceType).type
    },
    validateDescription() {
      return this.editingDescription && this.editingDescription.length <= 65536
    },
    placeType() {
      return this.$store.state.place.placeTypes
    }
  },
  methods: {
    //Element updates
    getPhoto(element) {
      return element.photo_url
          ? process.env.VUE_APP_BACKEND_URL + '/..' + element.photo_url + "&size=330"
          : this.isPlace(element) ? require('@/assets/images/default_pictures/place_default.svg') : require('@/assets/images/default_pictures/event_default.svg')
    },
    showEditPhotoDialog(element) {
      this.editingElement = element
      this.promptEditPhoto = true
    },
    addPhoto(photo) {
      this.addingPhoto = {id: photo.id, url: photo.url}
    },
    updatePhoto(photo) {
      this.$loading(true)
      if (this.isPlace(this.editingElement)) {
        this.$store.dispatch("place/updatePlace", {
          'id': this.editingElement.id,
          'photo': photo.id
        }).then(() => {
          this.updateElementList()
          this.$notify({
            time: 4000,
            title: "Modification",
            text: "La photo a bien été mise à jour",
            color: "primary"
          })
        }).catch(() => {
          this.$notify({
            time: 4000,
            title: "Erreur",
            text: "Une erreur technique est survenue lors de la mise à jour de la photo, veuillez réessayer plus tard",
            color: "danger"
          })
        }).finally(() => this.$loading(false))
      } else {
        this.$store.dispatch("event/updateEvent", {
          'id': this.editingElement.id,
          'photo': photo.id
        }).then(() => {
          this.updateElementList()
          this.$notify({
            time: 4000,
            title: "Modification",
            text: "La photo a bien été mise à jour",
            color: "primary"
          })
        }).catch(() => {
          this.$notify({
            time: 4000,
            title: "Erreur",
            text: "Une erreur technique est survenue lors de la mise à jour de la photo, veuillez réessayer plus tard",
            color: "danger"
          })
        }).finally(() => this.$loading(false))
      }
    },
    showDeletePhotoDialog(element) {
      this.editingElement = element
      this.promptDeletePhoto = true
    },
    deletePhoto() {
      this.$loading(true)
      if (this.isPlace(this.editingElement)) {
        this.$store.dispatch("place/updatePlace", {
          'id': this.editingElement.id,
          'photo': null
        }).then(() => {
          this.updateElementList()
          this.$notify({
            time: 4000,
            title: "Suppression",
            text: "La photo a bien été supprimée",
            color: "primary"
          })
        }).catch(() => {
          this.$notify({
            time: 4000,
            title: "Erreur",
            text: "Une erreur technique est survenue lors de la suppression la photo, veuillez réessayer plus tard",
            color: "danger"
          })
        }).finally(() => this.$loading(false))
      } else {
        this.$store.dispatch("event/updateEvent", {
          'id': this.editingElement.id,
          'photo': null
        }).then(() => {
          this.updateElementList()
          this.$notify({
            time: 4000,
            title: "Suppression",
            text: "La photo a bien été supprimée",
            color: "primary"
          })
        }).catch(() => {
          this.$notify({
            time: 4000,
            title: "Erreur",
            text: "Une erreur technique est survenue lors de la suppression la photo, veuillez réessayer plus tard",
            color: "danger"
          })
        }).finally(() => this.$loading(false))
      }
    },
    showEditTitleDialog(element) {
      this.editingElement = element
      this.promptErrorMessage = null
      this.editingTitle = element.title
      this.promptEditTitle = true
    },
    updateTitle() {
      let update = {
        id: this.editingElement.id,
        title: this.editingTitle
      }
      this.$loading(true)
      if (this.isPlace(this.editingElement)) {
        this.$store.dispatch("place/updatePlace", update)
            .then(() => {
              this.promptEditTitle = false
              this.$notify({
                time: 4000,
                title: "Lieu modifié",
                text: "Le changement de titre a bien été pris en compte",
                color: "primary"
              })
              this.updateElementList()
            })
            .catch(error => {
              if (error.response && error.response.status === 400) {
                this.promptErrorMessage = "Le titre choisi est invalide"
              } else {
                this.promptErrorMessage = "Erreur technique, veillez rééssayer plus tard"
              }
              this.promptEditTitle = true
            }).finally(() => this.$loading(false))
      } else {
        this.$store.dispatch("event/updateEvent", update)
            .then(() => {
              this.promptEditTitle = false
              this.$notify({
                time: 4000,
                title: "Evènement modifié",
                text: "Le changement de titre a bien été pris en compte",
                color: "primary"
              })
              this.updateElementList()
            })
            .catch(error => {
              if (error.response && error.response.status === 400) {
                this.promptErrorMessage = "Le titre choisi est invalide"
              } else {
                this.promptErrorMessage = "Erreur technique, veillez rééssayer plus tard"
              }
              this.promptEditTitle = true
            }).finally(() => this.$loading(false))
      }
    },
    showEditDescriptionDialog(element) {
      this.editingElement = element
      this.promptErrorMessage = null
      this.editingDescription = element.description
      this.promptEditDescription = true
    },
    updateDescription() {
      let update = {
        id: this.editingElement.id,
        description: this.editingDescription
      }
      this.$loading(true)
      if (this.isPlace(this.editingElement)) {
        this.$store.dispatch("place/updatePlace", update)
            .then(() => {
              this.promptEditDescription = false
              this.$notify({
                time: 4000,
                title: "Lieu modifié",
                text: "Le changement de description a bien été pris en compte",
                color: "primary"
              })
              this.updateElementList()
            })
            .catch(error => {
              if (error.response && error.response.status === 400) {
                this.promptErrorMessage = "La description choisie est invalide"
              } else {
                this.promptErrorMessage = "Erreur technique, veillez rééssayer plus tard"
              }
              this.promptEditDescription = true
            }).finally(() => this.$loading(false))
      } else {
        this.$store.dispatch("event/updateEvent", update)
            .then(() => {
              this.promptEditDescription = false
              this.$notify({
                time: 4000,
                title: "Evènement modifié",
                text: "Le changement de description a bien été pris en compte",
                color: "primary"
              })
              this.updateElementList()
            })
            .catch(error => {
              if (error.response && error.response.status === 400) {
                this.promptErrorMessage = "La description choisie est invalide"
              } else {
                this.promptErrorMessage = "Erreur technique, veillez rééssayer plus tard"
              }
              this.promptEditDescription = true
            }).finally(() => this.$loading(false))
      }
    },
    showEditLocation(element) {
      this.editingElement = element
      this.promptErrorMessage = null
      this.editingLocationAddress = element.location_address
      this.editingLocationPoint = element.location_point
      this.promptEditLocation = true
    },
    updateLocation() {
      let update = {
        id: this.editingElement.id,
        location_address: this.editingLocationAddress
      }
      if (this.editingLocationPoint != null) {
        update.location_point = "SRID=4326;POINT ("
            + this.editingLocationPoint[0] + " "
            + this.editingLocationPoint[1] + ")";
      } else {
        update.location_point = ""
      }
      this.$loading(true)
      if (this.isPlace(this.editingElement)) {
        this.$store.dispatch("place/updatePlace", update)
            .then(() => {
              this.promptEditLocation = false
              this.$notify({
                time: 4000,
                title: "Evènement modifié",
                text: "Le changement d'adresse a bien été pris en compte",
                color: "primary"
              })
              this.updateElementList()
            })
            .catch(error => {
              if (error.response && error.response.status === 400) {
                this.promptErrorMessage = "La nouvelle adresse est invalide"
              } else {
                this.promptErrorMessage = "Erreur technique, veillez rééssayer plus tard"
              }
              this.promptEditLocation = true
            }).finally(() => this.$loading(false))
      } else {
        this.$store.dispatch("event/updateEvent", update)
            .then(() => {
              this.promptEditLocation = false
              this.$notify({
                time: 4000,
                title: "Evènement modifié",
                text: "Le changement d'adresse a bien été pris en compte",
                color: "primary"
              })
              this.updateElementList()
            })
            .catch(error => {
              if (error.response && error.response.status === 400) {
                this.promptErrorMessage = "La nouvelle adresse est invalide"
              } else {
                this.promptErrorMessage = "Erreur technique, veillez rééssayer plus tard"
              }
              this.promptEditLocation = true
            }).finally(() => this.$loading(false))
      }
    },

    showEditLocationPoint() {
      if (this.editingLocationPoint == null) {
        this.markerPosition = [48.8534100, 2.3488000] // Default center at Paris
      } else {
        this.markerPosition = this.editingLocationPoint
      }
      this.mapCenter = this.markerPosition
      this.popUpEditLocationPoint = true

      // Work around for fixing map repaint issue
      setTimeout(() => {
        // Setup geosearch elemetn if not done
        if (!this.geosearchSetup) {
          this.$refs.map.mapObject.on('geosearch/showlocation', e => {
            this.markerPosition = [e.location.y, e.location.x]
          })
          this.geosearchSetup = true
        }

        this.$refs.map.mapObject.invalidateSize()
      }, 250)
    },
    selectLocationPoint() {
      this.editingLocationPoint = [this.markerPosition.lat, this.markerPosition.lng]
      this.popUpEditLocationPoint = false
    },
    onAddressSelected(location) {
      this.editingLocationPoint = [location.y, location.x]
    },
    showAddPartnerDialog(element) {
      this.editingElement = element
      this.promptErrorMessage = null
      this.editingPartnerName = null
      this.promptAddPartner = true
    },
    addPartner() {
      let partnerObj = this.partnerList.find(partner => partner.name === this.editingPartnerName)
      // Let's do the basics checks before sending the payload:
      //  - Partner not found
      if (partnerObj == null) {
        this.$notify({
          time: 4000,
          title: "Erreur",
          text: "Le partenaire est introuvable",
          color: "danger"
        })
        return
      }
      // - Already partner
      if (this.editingElement.partners.find(partner => partner.id === partnerObj.id)) {
        this.$notify({
          time: 4000,
          title: "Impossible",
          text: "Le partenaire a déjà été ajouté",
          color: "danger"
        })
        return
      }
      // Send the payload:
      let payload = {
        id: this.editingElement.id,
        local_authority: partnerObj.id
      }
      this.$loading(true)
      if (this.isPlace(this.editingElement)) {
        this.$store.dispatch("place/addPartner", payload)
            .then(() => {
              this.promptAddPartner = false
              this.$notify({
                time: 4000,
                title: "Evènement modifié",
                text: "Le nouveau partenaire a bien été ajouté",
                color: "primary"
              })
              this.updateElementList()
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                title: "Erreur technique",
                text: "Une erreur est survenue lors de l'ajout du nouveau partenaire, veillez rééssayer plus tard.",
                color: "danger"
              })
            }).finally(() => this.$loading(false))
      } else {
        this.$store.dispatch("event/addPartner", payload)
            .then(() => {
              this.promptAddPartner = false
              this.$notify({
                time: 4000,
                title: "Evènement modifié",
                text: "Le nouveau partenaire a bien été ajouté",
                color: "primary"
              })
              this.updateElementList()
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                title: "Erreur technique",
                text: "Une erreur est survenue lors de l'ajout du nouveau partenaire, veillez rééssayer plus tard.",
                color: "danger"
              })
            }).finally(() => this.$loading(false))
      }
    },
    showDeletePartnerDialog(element, partner) {
      this.editingElement = element
      this.promptErrorMessage = null
      this.promptDeletePartner = true
      this.editingPartner = partner
    },
    deletePartner() {
      let payload = {
        id: this.editingElement.id,
        local_authority: this.editingPartner.id
      }
      this.$loading(true)
      if (this.isPlace(this.editingElement)) {
        this.$store.dispatch("place/deletePartner", payload)
            .then(() => {
              this.promptDeletePartner = false
              this.$notify({
                time: 4000,
                title: "Evènement modifié",
                text: "Le nouveau partenaire a bien été supprimé",
                color: "warning"
              })
              this.updateElementList()
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                title: "Erreur technique",
                text: "Une erreur est survenue lors de la suppression du partenaire, veillez rééssayer plus tard.",
                color: "danger"
              })
            }).finally(() => this.$loading(false))
      } else {
        this.$store.dispatch("event/deletePartner", payload)
            .then(() => {
              this.promptDeletePartner = false
              this.$notify({
                time: 4000,
                title: "Evènement modifié",
                text: "Le nouveau partenaire a bien été supprimé",
                color: "warning"
              })
              this.updateElementList()
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                title: "Erreur technique",
                text: "Une erreur est survenue lors de la suppression du partenaire, veillez rééssayer plus tard.",
                color: "danger"
              })
            }).finally(() => this.$loading(false))
      }
    },
    showDeleteElementDialog(element) {
      this.editingElement = element
      this.promptErrorMessage = null
      this.promptDeleteElement = true
    },
    deleteElement() {
      this.$loading(true)
      if (this.isPlace(this.editingElement)) {
        this.$store.dispatch("place/deletePlace", this.editingElement.id)
            .then(() => {
              this.promptDeleteElement = false
              this.$notify({
                time: 4000,
                title: "Evènement supprimé",
                text: "Le lieu a bien été supprimé",
                color: "primary"
              })
              this.updateElementList()
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                title: "Erreur technique",
                text: "Une erreur est survenue lors de la suppression du lieu, veillez rééssayer plus tard.",
                color: "danger"
              })
            }).finally(() => this.$loading(false))
      } else {
        this.$store.dispatch("event/deleteEvent", this.editingElement.id)
            .then(() => {
              this.promptDeleteElement = false
              this.$notify({
                time: 4000,
                title: "Evènement supprimé",
                text: "L'évènement a bien été supprimé",
                color: "primary"
              })
              this.updateElementList()
            })
            .catch(() => {
              this.$notify({
                time: 4000,
                title: "Erreur technique",
                text: "Une erreur est survenue lors de la suppression de l'évènement, veillez rééssayer plus tard.",
                color: "danger"
              })
            }).finally(() => this.$loading(false))
      }
    },

    // For events
    showCreateEventDialog(event) {
      this.addingPhoto = null
      this.editingElement = event
      this.promptErrorMessage = null
      this.editingTitle = null
      this.editingDescription = null
      this.editingEventStartDate = moment().format()
      this.editingEventEndDate = moment().format()
      this.editingLocationAddress = null
      this.editingLocationPoint = null
      this.editingPartnerName = null
      this.popUpCreateEvent = true
      this.nmax_participants = null
      this.illimitedParticipants = true
    },
    createEvent() {
      let event = {
        creator: this.localAuthority.id,
        title: this.editingTitle,
        description: this.editingDescription,
        start_date: moment(this.editingEventStartDate).format(),
        end_date: moment(this.editingEventEndDate).format(),
        location_address: this.editingLocationAddress,
        nmax_participants: this.nmax_participants,
      }
      if (this.illimitedParticipants) {
        event.nmax_participants = null
      }

      if (this.addingPhoto) {
        event.photo = this.addingPhoto.id
      }
      if (this.editingLocationPoint != null) {
        event.location_point = "SRID=4326;POINT ("
            + this.editingLocationPoint[0] + " "
            + this.editingLocationPoint[1] + ")";
      }
      this.$loading(true)
      this.$store.dispatch("event/createEvent", event)
          .then(() => {
            this.popUpCreateEvent = false
            this.$notify({
              time: 4000,
              title: "Evènement créé",
              text: "L'évènement a bien été créé",
              color: "primary"
            })
            this.updateElementList()
          })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              this.promptErrorMessage = "Les informations saisies sont invalides"
            } else {
              this.promptErrorMessage = "Erreur technique, veuillez rééssayer plus tard"
            }
            this.promptEditLocalAuthorityName = true
          }).finally(() => this.$loading(false))

      this.illimitedParticipants = false
    },
    showEditEventDatesDialog(event) {
      this.editingElement = event
      this.promptErrorMessage = null
      this.editingEventStartDate = event.start_date.toISOString()
      this.editingEventEndDate = event.end_date.toISOString()
      this.promptEditEventDates = true
    },
    updateEventDates() {
      let update = {
        id: this.editingElement.id,
        start_date: this.editingEventStartDate,
        end_date: this.editingEventEndDate,
      }
      this.$loading(true)
      this.$store.dispatch("event/updateEvent", update)
          .then(() => {
            this.promptEditEventDates = false
            this.$notify({
              time: 4000,
              title: "Evènement modifié",
              text: "Le changement de dates a bien été pris en compte",
              color: "primary"
            })
            this.updateElementList()
          })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              this.promptErrorMessage = "Les dates choisies sont invalides"
            } else {
              this.promptErrorMessage = "Erreur technique, veillez rééssayer plus tard"
            }
            this.promptEditEventDates = true
          }).finally(() => this.$loading(false))
    },
    printNmaxParticipants(event) {
      if (event.nmax_participants != null) {
        return event.nmax_participants.toLocaleString()
      } else {
        return 'Illimité'
      }
    },
    showEditNmaxParticipants(event) {
      this.editingElement = event
      if (this.editingElement.nmax_participants != null) {
        this.nmax_participants = this.editingElement.nmax_participants
        this.illimitedParticipants = false
      } else {
        this.nmax_participants = this.editingElement.participants_count
        this.illimitedParticipants = true
      }
      this.minNmaxParticipants = this.editingElement.participants_count
      this.promptErrorMessage = null
      this.promptEditNmaxParticipants = true
    },
    updateNmaxParticipants() {
      let update = {
        id: this.editingElement.id,
        nmax_participants: this.nmax_participants,
      }
      if (this.illimitedParticipants) {
        update.nmax_participants = null
      }
      this.$loading(true)
      this.$store.dispatch("event/updateEvent", update)
          .then(() => {
            this.promptEditNmaxParticipants = false
            this.$notify({
              time: 4000,
              title: "Evènement modifié",
              text: "Le changement du nombre maximal de participant a bien été pris en compte",
              color: "primary"
            })
            this.updateElementList()
          })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              this.promptErrorMessage = "Le nombre maximal de participants choisi (" + update.nmax_participants.toLocaleString() +
                  ") est plus faible que le nombre de participants déjà inscrits (" + this.editingElement.participants_count.toLocaleString() + ")"
            } else {
              this.promptErrorMessage = "Erreur technique, veillez rééssayer plus tard"
            }
            this.promptEditNmaxParticipants = true
          }).finally(() => this.$loading(false))
    },
    showParticipants(item) {
      this.activeEvent = item
      this.popUpEventParticipants = true
    },
    checkDates() {
      this.configEndDateTimePicker.minDate = this.editingEventStartDate
      this.configStartDateTimePicker.maxDate = this.editingEventEndDate
    },

    //For places
    showCreatePlaceDialog(place) {
      this.addingPhoto = null
      this.editingPlace = place
      this.promptErrorMessage = null
      this.editingTitle = null
      this.editingDescription = null
      this.editingPlaceType = null
      this.editingLocationAddress = null
      this.editingLocationPoint = null
      this.editingPartnerName = null
      this.popUpCreatePlace = true
    },
    createPlace() {
      let place = {
        creator: this.localAuthority.id,
        title: this.editingTitle,
        description: this.editingDescription,
        type: this.typeParsing,
        location_address: this.editingLocationAddress
      }
      if (this.addingPhoto) {
        place.photo = this.addingPhoto.id
      }
      if (this.editingLocationPoint != null) {
        place.location_point = "SRID=4326;POINT ("
            + this.editingLocationPoint[0] + " "
            + this.editingLocationPoint[1] + ")";
      }
      this.$loading(true)
      this.$store.dispatch("place/createPlace", place)
          .then(() => {
            this.popUpCreatePlace = false
            this.$notify({
              time: 4000,
              title: "Lieu créé",
              text: "Le lieu a bien été créé",
              color: "primary"
            })
            this.updateElementList()
          })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              this.promptErrorMessage = "Les informations saisies sont invalides"
            } else {
              this.promptErrorMessage = "Erreur technique, veuillez rééssayer plus tard"
            }
            this.promptEditLocalAuthorityName = true
          }).finally(() => this.$loading(false))
    },
    showEditPlaceTypeDialog(place) {
      this.editingPlace = place
      this.promptErrorMessage = null
      this.editingPlaceType = place.type
      this.promptEditPlaceType = true
    },
    updatePlaceType() {
      let update = {
        id: this.editingPlace.id,
        type: this.typeParsing
      }
      this.$loading(true)
      this.$store.dispatch("place/updatePlace", update)
          .then(() => {
            this.promptEditPlaceType = false
            this.$notify({
              time: 4000,
              title: "Lieu modifié",
              text: "Le changement de type a bien été pris en compte",
              color: "primary"
            })
            this.updateElementList()
          })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              this.promptErrorMessage = "La description choisie est invalide"
            } else {
              this.promptErrorMessage = "Erreur technique, veillez rééssayer plus tard"
            }
            this.promptEditPlaceType = true
          }).finally(() => this.$loading(false))
    },
    typeUnparse(type) {
      return this.placeType.find(item => item.type === type).name
    },

    // General
    isPlace(element) {
      return element.hasOwnProperty('type');
    },
    comingEvents() {
      this.showPastEvents = false
      this.showPlaces = false
      this.elementList = []
      this.elementListOffset = 0
      this.fetchNextElements()
    },
    pastEvents() {
      this.showPastEvents = true
      this.showPlaces = false
      this.elementList = []
      this.elementListOffset = 0
      this.fetchNextElements()
    },
    getPlaces() {
      this.showPastEvents = false
      this.showPlaces = true
      this.elementList = []
      this.elementListOffset = 0
      this.fetchNextElements()
    },
    fetchNextElements() {
      let payload = {
        limit: this.elementListLimit,
        offset: this.elementListOffset,
        local_authority: this.localAuthority.id
      }
      if (this.showPlaces) {
        this.$store.dispatch("place/fetchPlaces", payload)
            .then(response => {
              this.elementListNext = response.next;
              this.elementListOffset = this.elementListOffset + this.elementListLimit
              this.elementList = this.elementList.concat(response.results);
              this.waitScroll = false
            }).catch(() => {
          this.waitScroll = false
          this.$notify({
            time: 4000,
            title: "Erreur",
            color: "danger",
            text: "Une erreur est survenue lors de la récupération des lieux"
          })
        });
      } else {
        if (this.showPastEvents) {
          payload.ordering = '-start_date'
          payload.before_date = new Date(Date.now()).toISOString()
        } else {
          payload.ordering = 'start_date'
          payload.after_date = new Date(Date.now()).toISOString()
        }
        this.$store.dispatch("event/fetchEvents", payload)
            .then(response => {
              this.elementListNext = response.next;
              this.elementListOffset = this.elementListOffset + this.elementListLimit
              this.elementList = this.elementList.concat(response.results);
              this.waitScroll = false
            }).catch(() => {
          this.waitScroll = false
          this.$notify({
            time: 4000,
            title: "Erreur",
            color: "danger",
            text: "Une erreur est survenue lors de la récupération des évènements"
          })
        });
      }
    },
    updateElementList() {
      this.elementList = []
      let limit = this.elementListLimit
      this.elementListLimit = this.elementListOffset + limit
      this.elementListOffset = 0
      this.fetchNextElements()
      this.elementListLimit = limit
    },
    isCreator(element) {
      return element.creator_info.id === this.localAuthority.id
    },
    scroll(event) {
      if (this.elementListNext && event.target.scrollTop + event.target.offsetHeight > event.target.scrollHeight - 50) {
        if (!this.waitScroll) {
          this.waitScroll = true
          this.currentScroll = event.target.scrollTop
          this.fetchNextElements()
        }
      }
    },
    geosearch(searchText) {
      return new Promise((resolve, reject) => {
        this.geosearchOptions.provider.search({query: searchText})
            .then(results => {
              resolve(results)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    dateMapZoom(zoom) {
      this.mapZoom = zoom
    },
    updateMapCenter(center) {
      if (center != null) {
        this.mapCenter = center
      }
    },
    updateMapBounds(bounds) {
      this.mapBounds = bounds
    },
    updateMapZoom(zoom) {
      this.mapZoom = zoom
    },
    paragraphWithLink(paragraph)  {
      let urlRegex = /((https?:\/\/)[^\s]+)/g;
      let paragraphWithLinks = paragraph.replace(urlRegex, '<a href="$1" id="paragraphLink">$1</a>');
      return DOMPurify.sanitize(paragraphWithLinks, {ALLOWED_TAGS: ['a'], ALLOWED_ATTR: ['href', 'id']});
    }
  },
}
</script>

<style>
/* global styling */
.leaflet-control-geosearch *,
.leaflet-control-geosearch *:before,
.leaflet-control-geosearch *:after {
  box-sizing: border-box;
}

/* leaflet button styling */
.leaflet-control-geosearch .leaflet-bar-part {
  border-radius: 4px;
  border-bottom: none;
}

.leaflet-control-geosearch a.leaflet-bar-part:before,
.leaflet-control-geosearch a.leaflet-bar-part:after {
  position: absolute;
  display: block;
  content: '';
}

/* magnifying glass */
.leaflet-control-geosearch a.leaflet-bar-part:before {
  top: 19px;
  left: 16px;
  width: 8px;
  border-top: 2px solid #555;
  transform: rotate(45deg);
}

.leaflet-control-geosearch a.leaflet-bar-part:after {
  top: 6px;
  left: 6px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #555;
}

/* resets for pending and error icons */
.leaflet-control-geosearch.error a.leaflet-bar-part:before,
.leaflet-control-geosearch.pending a.leaflet-bar-part:before {
  display: none;
}

.leaflet-control-geosearch.pending a.leaflet-bar-part:after,
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  left: 50%;
  top: 50%;
  width: 18px;
  height: 18px;
  margin: -9px 0 0 -9px;
  border-radius: 50%;
}

/* pending icon */
.leaflet-control-geosearch.pending a.leaflet-bar-part:after {
  content: '';
  border: 2px solid #555;
  border-top: 2px solid #f3f3f3;
  animation: spin 1s linear infinite;
}

/* error icon */
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  content: '!';
  line-height: initial;
  font-weight: 600;
  font-size: 18px;
  border: none;
}

/* search form styling */
.leaflet-control-geosearch form {
  display: none;
  position: absolute;
  top: -2px;
  left: 28px;
  border-radius: 0 4px 4px 0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left: none;
  background-color: #fff;
  background-clip: padding-box;
  z-index: -1;
  height: auto;
  margin: 0;
  padding: 0 8px;
}

.leaflet-control-geosearch.active form {
  display: block;
}

.leaflet-control-geosearch form input {
  min-width: 200px;
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  height: 30px;
  border-radius: 0 4px 4px 0;
  text-indent: 8px;
}

.leaflet-control-geosearch .results {
  background: #fff;
}

.leaflet-control-geosearch .results > * {
  line-height: 24px;
  padding: 0 8px;
  border: 1px solid transparent;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaflet-control-geosearch .results.active {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}

.leaflet-control-geosearch .results > .active,
.leaflet-control-geosearch .results > :hover {
  background-color: #f8f8f8;
  border-color: #c6c6c6;
  cursor: pointer;
}

/* add missing border to form */
.leaflet-control-geosearch .results.active:after {
  content: '';
  display: block;
  width: 0;
  border-left: 2px solid rgba(0, 0, 0, .2);
  position: absolute;
  left: -2px;
  bottom: -2px;
  top: 30px;
}

/* animations */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.leaflet-top .leaflet-control-geosearch.bar,
.leaflet-bottom .leaflet-control-geosearch.bar {
  display: none;
}

.leaflet-control-geosearch.bar {
  position: relative;
  display: block;
  height: auto;
  width: 400px;
  margin: 10px auto 0;
  cursor: auto;
  z-index: 1000;
}

.leaflet-control-geosearch.bar form {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.leaflet-control-geosearch.bar form input {
  min-width: 100%;
  width: 100%;
}

.leaflet-control-geosearch.bar .results.active:after {
  opacity: .2;
}

.leaflet-right .leaflet-control-geosearch form {
  right: 28px;
  left: initial;
  border-radius: 4px 0 0 4px;
  border-left: inherit;
  border-right: none;
}

.leaflet-control-geosearch a.reset {
  color: black;
  position: absolute;
  line-height: 30px;
  padding: 0 8px;
  right: 0;
  top: 0;
  cursor: pointer;
  border: none;
}

.leaflet-control-geosearch a.reset:hover {
  background: #f5f5f5;
}

.vue-simple-suggest.designed .input-wrapper input {
  border: 1px solid #d8d6de !important;
  border-radius: 5px 5px 5px 5px;
  padding: 0.438rem 1rem;
}

.vue-simple-suggest.designed.focus .input-wrapper input {
  border: 1px solid var(--primary) !important;
}

.vue-simple-suggest ::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}


</style>
